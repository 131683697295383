import React from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import NavPage from "./NavPage"; 

const MainPage = () => {
    return (
        <React.Fragment>
            {/* Header Section */}
            <section>
                <div>
                    <Header />
                </div>
            </section>

            {/* Side Bar Section */}
            <section>
                <div className="sideNav">
                    <div className="sideNavSideBar">
                        <SideBar />
                    </div>
                    <div className="sideNavNavPage">
                        <NavPage />
                    </div>
                </div>
            </section> 
        </React.Fragment>
    );
};

export default MainPage;