import React, { useEffect, useState } from "react";
import MainPage from "../layout/MainPage";
import { BrowserRouter, } from "react-router-dom";
import { ThemeProvider, } from "@mui/material";
import colorTheme from "../assets/colorTheme";
import PageLoad from "./PageLoad";
import { useDispatch } from "react-redux";
import { setLoading, setNeedSetupRedirect } from "../state/appSlice";
import OriginService from "../services/originService";
import { useActions } from "../hooks/useActions";
import AdminSetupModal from "./AdminSetupModal";
import { store } from "../state/store";
import ElliHost from "../services/elliHost";  

const App = () => {

    const origin = new OriginService();
    const { getConfigPlugInData, getConfigOtherControlsData, getConfigMiscData } = useActions();
    const dispatch = useDispatch();
    const [adminModalOpen, setAdminModalOpen] = useState(false);
    const [adminModalText, setAdminModalText] = useState("");

    const handleCloseAdminModal = () => {
        const elliHost = ElliHost.getInstance();
        elliHost.closeApplication();
    };

    useEffect(() => {
        const buildAppStart = async () => {
            dispatch(setLoading(true));
            const originResponse = await origin.buildOrigin();
            const isAdmin = store.getState().appSlice.isAdmin;

            if (!originResponse.data.result.oAuthToken.accessToken) {
                setAdminModalText("Contact your Administrator. Configuration missing credentials and/or ConfigID in Encompass Credential Manager.");
                setAdminModalOpen(true);
            }
            else if (!originResponse.data.result.configId) {
                if (isAdmin) {
                    dispatch(setNeedSetupRedirect(true));
                }
                else {
                    setAdminModalText("Current user is not an Admin user. Contact your Administrator.");
                    setAdminModalOpen(true);
                }
            }
            ///Load Everything needed here
            else {
                await getConfigPlugInData(originResponse.data.result.configId, originResponse.data.result.oAuthToken.accessToken);
                await getConfigOtherControlsData(originResponse.data.result.configId, originResponse.data.result.oAuthToken.accessToken);
                await getConfigMiscData(originResponse.data.result.configId, originResponse.data.result.oAuthToken.accessToken);
            }
        }

        //Not in Encompass EPC Window testing
        if (window.top === window.self) {
            dispatch(setLoading(true));
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 3000)
            return;
        }

        buildAppStart()
            .catch(console.error)
            .finally(() => {
                dispatch(setLoading(false));
            });
    }, [])

    return (
        <React.Fragment>
            <ThemeProvider theme={colorTheme}>
                <PageLoad />
                <BrowserRouter>
                    <MainPage />
                </BrowserRouter>
                <AdminSetupModal open={adminModalOpen} onClose={handleCloseAdminModal} text={adminModalText} />
            </ThemeProvider>
        </React.Fragment>
    );
};

export default App;