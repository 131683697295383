import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IDocumentMappingConfiguration } from "../../models/configuration/bundles/IBundles";

// This action creator gets the server config values from an api call or state
const getDocumentMappingData = (configId: string, token: string | null) => {
    return async (dispatch: Dispatch<Action>) => {
      
        dispatch({ 
            type: ActionType.GET_DOCUMENT_MAPPING_DATA
        });
        
        const pluginService = new PluginService();
  
        try {
            const payload = await pluginService.getConfig<IDocumentMappingConfiguration>(token, "document-map", configId);
            dispatch({
                type: ActionType.GET_DOCUMENT_MAPPING_DATA_SUCCESS,
                payload: payload,
            });
        
        } catch (error: any) {
            
            console.error("Get Document Mapping Data");
            if (error instanceof Error) {
                var payloadErrorMessage = `Failed to fetch Document Mapping Data: (${error.message})`;
                dispatch({
                  type: ActionType.GET_DOCUMENT_MAPPING_DATA_FAILURE,
                  payload: payloadErrorMessage,
                });
            }

        }
    }
}

export default getDocumentMappingData;