// create action creator for saving config message data
import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IErrorMessageOverrides, IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";
import EncompassService from "../../services/encompassService";

const saveConfigMessagesData = (configId: string, updatedMessages: IErrorMessageOverrides[], token: string | null) => {
    
    return async (dispatch: Dispatch<Action>, getState: () => any) => {
        dispatch({ type: ActionType.SAVE_MESSAGES });
        try {
            const { data: pluginConfiguration } = getState().configMessages;
            if (!pluginConfiguration) {
                throw new Error(" No PluginConfiguration available in state to save");
            }

            if (!updatedMessages || updatedMessages.length === 0) {
                throw new Error("No Messages to save");
            }

            const updatedConfig = {
                ...pluginConfiguration.Configuration,
                ErrorMessageOverrides: updatedMessages
            };

            const requestBody = {
                "ConfigurationId": configId,
                "EncompassInstanceId": EncompassService.getEncompassInstanceId(),
                "Configuration": updatedConfig
            };
            
            await new PluginService().saveConfig<IPluginConfiguration>(token, "plugin", configId, requestBody);

            dispatch({
                type: ActionType.SAVE_MESSAGES_SUCCESS,
                payload: { "Configuration": updatedConfig }
            });

            return Promise.resolve();

        } catch (error: any) {
            const payloadErrorMessage = `Failed to save Messages data: (${error.message})`;
            dispatch({
                type: ActionType.SAVE_SEND_VALIDATIONS_FAILURE,
                payload: payloadErrorMessage,
            });

            return Promise.reject(payloadErrorMessage);
        }
    }
}

export default saveConfigMessagesData;