import { Box, Button, Card, CardContent, CardHeader, Checkbox, Container, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, store } from "../state/store";
import { useEffect, useState } from "react";
import { IPackage } from "../models/configuration/plugin/IConfiguration";
import { useTypedSelector } from "../hooks/useTypedSelector";
import AttachmentsCard from "../components/AttachmentsCard";
import OrderTabs from "../components/order/OrderTabs";
import SelectToPrintCard from "../components/SelectToPrintCard";

const OrderPage = () => {
    const appState = useSelector((state: RootState) => state.appSlice)
    const controlsState = useSelector((state: RootState) => state.configOtherControls)
    const [deliveryMethods, setDeliveryMethods] = useState<string[]>([])
    const packages = useTypedSelector((state) => state.configPlugInData.data?.Configuration.Packages);
    const configMisc = useTypedSelector((state) => state.configMiscDefaults.data?.Configuration?.DefaultValues)
    const [packageTypes, setPackageTypes] = useState<Array<IPackage>>([]);

    const [attachmentsVisible, setAttachmentsVisible] = useState(false);
    const [attachmentsEnable, setAttachmentsEnable] = useState(false);
    const [selectedVisible, setSelectedVisible] = useState(false);
    const [selectedEnable, setSelectedEnable] = useState(false);
    const [attachmentsTitle, setAttachmentsTitle] = useState("Attachments");
    const [selectToPrintTitle, setSelectToPrintTitle] = useState("Selected");
    const [orderCount, setOrderCount] = useState(0);

    const handlePackageChange = (dm: string[]) => {
        setDeliveryMethods(dm)
    }

    function setAttachmentOptions(attachmentOptions: any, currentState: any) {
        if (attachmentOptions) {
            if (attachmentOptions.Caption) {
                setAttachmentsTitle(attachmentOptions.Caption)
            }
            let doesUserHaveAttachments = false;
            if (attachmentOptions.Roles?.includes('-All Users-'))
                doesUserHaveAttachments = true;
            attachmentOptions.Roles?.forEach((r: any) => {
                if (currentState.userPersonas.includes(r))
                    doesUserHaveAttachments = true;
            })
            if (currentState.userId && attachmentOptions.Users?.includes(currentState.userId)) {
                doesUserHaveAttachments = true;
            }
            if (doesUserHaveAttachments) {
                setAttachmentsVisible(true);
                setAttachmentsEnable(true);
            }
            else {
                if (attachmentOptions.Enabled && attachmentOptions.ControlBehavior === "Visible") {
                    setAttachmentsEnable(false);
                    setAttachmentsVisible(true)
                }
            }
        }
    }

    function setSelectDocOptions(selectDocOptions: any, currentState: any) {
        if (selectDocOptions) {
            if (selectDocOptions?.Caption) {
                setSelectToPrintTitle(selectDocOptions.Caption);
            }
            let doesUserHaveAttachments = false;
            if (selectDocOptions.Roles?.includes('-All Users-'))
                doesUserHaveAttachments = true;
            selectDocOptions.Roles?.forEach((r: any) => {
                if (currentState.userPersonas.includes(r))
                    doesUserHaveAttachments = true;
            })
            if (currentState.userId && selectDocOptions.Users?.includes(currentState.userId)) {
                doesUserHaveAttachments = true;
            }

            if (doesUserHaveAttachments) {
                setSelectedEnable(true);
                setSelectedVisible(true);
            }
            else {
                if (selectDocOptions.Enabled && selectDocOptions.ControlBehavior === "Visible") {
                    setSelectedEnable(false);
                    setSelectedVisible(true)
                }
            }
        }
    }

    useEffect(() => {
        let pt = packages?.filter((d) => d.Group === appState.page || d.ParentPackageId === appState.page);
        setPackageTypes(pt?.sort((a, b) => (a.Order ?? 999) - (b.Order ?? 999)) ?? []);
        setDeliveryMethods([])
    }, [appState.page, packages])

    useEffect(() => {
        const otherControlOptions = controlsState.data?.Configuration?.OtherControls;
        const attachmentControlOpts = otherControlOptions?.find(c => c.Id === "pnlAttachments");
        const selectDocOptions = otherControlOptions?.find(c => c.Id === "pnlSelectToPrint");
        const currentAppState = store.getState().appSlice;

        setAttachmentOptions(attachmentControlOpts, currentAppState);
        setSelectDocOptions(selectDocOptions, currentAppState);

    }, [controlsState])

    const submitHandler = () => {
        setOrderCount(prevCount => prevCount + 1)
    }
    return <div>
        <Box
            component="main"
            alignItems="top"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                overflow: "auto",
                minHeight: '100vh',
            }}
        >
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }} >
                <Grid container spacing={3} justifyContent="space-between" alignItems="stretch">
                    <Grid item xs={6}  >
                        <Typography variant="h4">{appState.page}</Typography>
                    </Grid>
                    <Grid item xs={6}  >
                        <Stack direction="row" justifyContent="end" spacing={1}>
                            <Button 
                                variant="contained"
                                size="small"
                                onClick={() => setOrderCount(0)}
                            >
                            Refresh
                            </Button>
                            <Button 
                                variant="contained"
                                size="small"
                                color="success"
                                disabled={ configMisc?.RequireRefreshBeforeSubsequentSend && orderCount > 0 }
                                onClick={() => submitHandler()}
                                >
                                Send
                            </Button>
                            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                                <InputLabel>Request Type</InputLabel>
                                <Select label="Request Type" defaultValue="LightsOut">
                                    <MenuItem value="LightsOut">LightsOut</MenuItem>
                                    <MenuItem value="LightsOn">LightsOn</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Card
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 300,
                            }}
                        >
                            <CardContent>
                                <FormControl>
                                    <RadioGroup
                                        name={`${appState.page?.replace(/\s/g, '')}-radio}`}>
                                        {packageTypes.map((p, i) =>
                                            <FormControlLabel 
                                                sx={{
                                                    color: !p.ParentPackageId ? 'black' : configMisc?.AlternatePackageColor
                                                }}
                                                value={p.Caption}
                                                control={<Radio />}
                                                label={p.Caption}
                                                key={`packageType-${i}`}
                                                onChange={() => handlePackageChange(p.ValidDeliveryTypes ?? [])}
                                            />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={6} lg={6}>
                        <Card
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 300,
                            }}
                        >
                            <CardHeader title="Delivery Method"></CardHeader>
                            <CardContent sx={{ overflow: 'auto' }}>
                                <FormGroup>
                                    {deliveryMethods.map((dm, i) =>
                                        <FormControlLabel control={<Checkbox value={dm} />} label={dm} key={`dmethod-${i}`} />
                                    )}
                                </FormGroup>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Recent Orders */}
                    <Grid item xs={6}>
                        <AttachmentsCard visible={attachmentsVisible} enabled={attachmentsEnable} title={attachmentsTitle} />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectToPrintCard visible={selectedVisible} enabled={selectedEnable} title={selectToPrintTitle} />
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12} lg={12} xl={12}>
                    <OrderTabs />
                </Grid>
            </Container>
            <Container sx={{ mt: 0, mb: 1 }}>
                <Grid item xs={12} justifyContent="end">
                    <Stack direction="row" justifyContent="end" spacing={1}>
                        <Button variant="contained" size="small" color="info"  >Why can't I disclose?</Button>
                    </Stack>
                </Grid>
            </Container>
        </Box>
    </div>
};

export default OrderPage;

