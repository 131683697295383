import React from "react";
import { ThemeProvider, Checkbox, Table, TableRow, TableCell, TableHead, TableContainer, TableBody, Typography, Select, MenuItem, SxProps } from "@mui/material";
import colorTheme from "../../../assets/colorTheme";
import { IDataColumn, DTDataGridColumnType, DTDataGridAlignment } from "../../../models/IDataColumn";
import DTDataGridTextEdit from "./DTDataGridTextEdit";
import "../../../assets/style.css";

interface DataGridProps {
    columns: Array<IDataColumn>;
    data: Array<any>;
    tableName: string;
    containerSx?: SxProps | undefined;
}

const DTDataGrid: React.FC<DataGridProps> = (props: DataGridProps) => {

    function getCellValue(row: any, col: IDataColumn) {
        if (col.dataFunction != null) {
            return col.dataFunction(row);
        }

        return getProperty(row, col.fieldId);
    }

    function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
        return o[propertyName];
    }

    function getTableCell(
        row: any,
        col: IDataColumn,
        rowindex: number,
        colindex: number
    ) {
        let cellKey = ["tc", rowindex.toString(), colindex.toString()].join("");
        if (col.datatype === DTDataGridColumnType.text) {
            return (
                <DTDataGridTextEdit
                    key={cellKey}
                    itemName={col.fieldId}
                    text={getCellValue(row, col)}
                    cellKey={cellKey}
                    alignmentClass={getAlignmentClass(col)}
                    dataChangeFunction={col.dataChangeFunction}
                    rowIndex={row}
                    editOnClick={col.editOnClick}
                />
            );
        }
        if (col.datatype === DTDataGridColumnType.link) {
            return (
                <TableCell
                    key={cellKey}
                    sx={{
                        color: col.foreColor,
                        textDecoration: "underline",
                        cursor: "pointer",
                    }}
                >
                    <div
                        className={[getAlignmentClass(col)].join(" ")}
                        onClick={(e) =>
                            col.callbackFunction ? col.callbackFunction(e, row) : null
                        }
                    >
                        {col.linkText}
                    </div>
                </TableCell>
            );
        }
        if (col.datatype === DTDataGridColumnType.checkbox) {
            return (
                <TableCell key={cellKey}>
                    <div className={[getAlignmentClass(col)].join(" ")}>
                        <Checkbox size="small" checked={getCellValue(row, col)} />
                    </div>
                </TableCell>
            );
        }

        if (col.datatype === DTDataGridColumnType.dropdownlist) {
            return (
                <TableCell key={cellKey} className={[getAlignmentClass(col)].join(" ")}>
                    <Select
                        sx={{ minWidth: "32px" }}
                        label={col.name ?? ""}
                        name={col.fieldId}
                        value={getCellValue(row, col)}
                        onChange={(e) =>
                            col.dataChangeFunction ? col.dataChangeFunction(e, row) : null
                        }
                        fullWidth
                        variant="outlined"
                    >
                        {col.dataOptions?.map((opt, i) => {
                            return (
                                <MenuItem
                                    key={[
                                        "mi",
                                        colindex.toString(),
                                        rowindex.toString(),
                                        i.toString(),
                                    ].join("")}
                                    value={opt}
                                >
                                    {opt}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </TableCell>
            );
        }
    }

    function getTableHeaderCell(col: IDataColumn, index: number) {
        return (
            <TableCell component="th" scope="row" key={index}>
                <div className={[getAlignmentClass(col)].join(" ")}>
                    <Typography fontWeight={"fontWeightBold"}>{col.name}</Typography>
                </div>
            </TableCell>
        );
    }

    function getAlignmentClass(col: IDataColumn) {
        if (col.alignment == null || col.alignment === DTDataGridAlignment.left) {
            return "flexLeft";
        }
        if (col.alignment === DTDataGridAlignment.right) {
            return "flexRight";
        }
        if (col.alignment === DTDataGridAlignment.center) {
            return "flexCenter";
        }
    }

    return (
        <>
            {props !== null}{" "}
            {
                <ThemeProvider theme={colorTheme}>
                    <>
                        <TableContainer component="main" sx={props.containerSx}>
                            <Table
                                stickyHeader
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === "light"
                                            ? theme.palette.grey[100]
                                            : theme.palette.grey[900],
                                    flexGrow: 1,
                                    overflow: "auto",
                                    height: "100%",
                                    maxHeight: "10vh",
                                }}
                            >
                                <TableHead
                                    sx={{
                                        background: "lightgrey",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <TableRow>
                                        {props.columns?.map((col, i) => getTableHeaderCell(col, i))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.data?.map((row: Array<any>, r) => (
                                        <TableRow
                                            key={r}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            {props.columns?.map((col: IDataColumn, c) =>
                                                getTableCell(row, col, r, c)
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                </ThemeProvider>
            }
        </>
    );
};

export default DTDataGrid;