import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IBundleConfiguration, IDocumentBundle } from "../../models/configuration/bundles/IBundles";

const saveConfigBundleData = (configId: string, updatedBundlingData: Array<IDocumentBundle>, token: string | null) => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {

        dispatch({
            type: ActionType.SAVE_CONFIG_BUNDLE_DATA
        });

        const pluginService = new PluginService();
        
        try {
            // console.log("🚀 ~ saveConfigBundleData ~ saveConfigBundleData: ", saveConfigBundleData);
            if (!updatedBundlingData || updatedBundlingData.length === 0) {
                console.log("🚀 ~ saveConfigBundleData --> No Bundles to save");
                throw new Error("No Bundles to save");
            } 
            
            const { data: bundlingConfiguration } = getState().configDocumentBundles;
           if (!bundlingConfiguration) {
                throw new Error("No BundlingConfiguration available in state to save");
            }

            const updatedConfig = {
                ...bundlingConfiguration.Configuration,
                Bundling: updatedBundlingData
            }

            const requestBody = {
                "ConfigurationId": configId,
                "Configuration": updatedConfig
            };

            console.log("saveConfigBundleData --> requestBody: ", requestBody);

            await pluginService.saveConfig<IBundleConfiguration>(token, "document-bundling", configId, requestBody);
            
            console.log("saveConfigBundleData --> SUCCESS");
            dispatch({
                type: ActionType.SAVE_CONFIG_BUNDLE_DATA_SUCCESS,
                payload: { "Configuration": updatedConfig }
            });

        } catch (error) {
            console.log("saveConfigbundleData --> FAILURE");
            if (error instanceof Error) {
                const payloadErrorMessage = `Failed to Save Bundles Configuration Data: (${error.message})`;
                dispatch({
                    type: ActionType.SAVE_CONFIG_BUNDLE_DATA_FAILURE,
                    payload: payloadErrorMessage
                });
                throw new Error(payloadErrorMessage);
            }
        }
    }
}

export default saveConfigBundleData;