import axios from 'axios';

class ConformXService {
    private baseURL: string;

    constructor(baseURL: string) {
        this.baseURL = baseURL;
    }

    public async ping(): Promise<boolean> {
        try {
           
            const response = await axios.post(`${this.baseURL}/HelloWorld`, null, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            if (response.status === 200) {
                if (response.data) {
                    return true;
                }
            }

            return false;

        } catch (error) {
            console.log("ConformXService Test Connection: ", error);
            return false; 
        }
    }
}

export default ConformXService;