import { Button, Card, CardContent, CardHeader, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import DtTab from "./DtTab";
import LogTabs from "./LogTabs";


const OrderTabs = () => {

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }
 
    return (
        <Card>
            <CardContent sx={{ padding: 0 }}>
                <Tabs value={tabValue} onChange={handleTabChange}  >
                    <Tab label="Request/Response" />
                    <Tab label="Logs" />
                </Tabs>
                <DtTab value={tabValue} index={0}>
                    <Card sx={{ marginBottom: 5 }}>
                        <CardHeader title="Request"></CardHeader>
                        <CardContent sx={{ border: '2px solid grey' }}> Content</CardContent>
                    </Card>
                    <Card>
                        <CardHeader title="Response" action={<Button variant="outlined" size="small" color="info">Preview Documents</Button>}></CardHeader>
                        <CardContent sx={{ border: '2px solid grey' }}>hey</CardContent>
                    </Card>
                </DtTab>
                <DtTab value={tabValue} index={1}>
                    <LogTabs />
                </DtTab>
            </CardContent>
        </Card>
    )
}
export default OrderTabs;