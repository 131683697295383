import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IAppState, IEncompassRole, IFileResource } from "../models/IAppState";

const initialState = {
    page: '/',
    loading: true,
    accessToken: null,
    expirationDateTimeUTC: new Date().toISOString(),
    encompassRoles: [],
    userPersonas: [], 
    configId: null,
    isAdmin: false,
    needSetupRedirect: false,
    attachments: []
} satisfies IAppState as IAppState;

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setPage(state, action: PayloadAction<string>) {
            state.page = action.payload
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        setAuthToken(state, action: PayloadAction<{ token: string, expires: string }>) {
            if (action.payload.token) {
                state.accessToken = action.payload.token;
                state.expirationDateTimeUTC = action.payload.expires;
            }
        },
        setConfigId(state, action: PayloadAction<string>) {
            state.configId = action.payload;
        },
        setEncompassRoles(state, action: PayloadAction<Array<IEncompassRole>>) {
            state.encompassRoles = action.payload;
        },
        setNeedSetupRedirect(state, action: PayloadAction<boolean>) {
            state.needSetupRedirect = action.payload
        },
        setUserPersonas(state, action: PayloadAction<string[]>) {
            state.userPersonas = action.payload;
            if (action?.payload?.length > 0) {
                action.payload.forEach((r) => {
                    if (r.toLowerCase().includes("admin")) {
                        state.isAdmin = true;
                    }
                })
            }
        },
        setUserId(state,action:PayloadAction<string>){
            state.userId = action?.payload?.substring(0,action?.payload?.indexOf("@"));
        },
        addAttachments(state, action: PayloadAction<Array<IFileResource>>) {
            action.payload.forEach((attachment) => {
                state.attachments.push(attachment);
            }) 
        },
        clearAttachments(state) {
            state.attachments = [];
        },
        removeAttachment(state, action: PayloadAction<string>) {
            state.attachments = state.attachments.filter(item => item.id !== action.payload);
        },

    }
})

export const { setPage,
    setLoading,
    setAuthToken,
    setEncompassRoles,
    setConfigId,
    setNeedSetupRedirect,
    setUserPersonas,
    setUserId,
    addAttachments,
    clearAttachments,
    removeAttachment
} = appSlice.actions;
export default appSlice.reducer