import axios from 'axios';

const ENCOMPASS_REQUEST_API_URI = process.env.REACT_APP_DT_REQUEST_API_URI;
const ENCOMPASS_PLUGIN_API_URI = process.env.REACT_APP_DT_PLUGIN_API_URI;
const ENCOMPASS_TOKEN_ENDPOINT_API_URI = process.env.REACT_APP_DT_TOKEN_ENDPOINT_API_URI;
const ENCOMPASS_DEFAULT_INSTANCE_ID = process.env.REACT_APP_DT_DEFAULT_ENCOMPASS_INSTANCEID as string; // Temporary
const ENCOMPASS_DEFAULT_CONFIG_ID = process.env.REACT_APP_DT_DEFAULT_CONFIG_ID as string; // Temporary

class EncompassService {
    private baseURL: string;

    constructor(baseURL: string) {
        this.baseURL = baseURL;
    }

    public async ping(): Promise<boolean> {
        try {
           
            const response = await axios.get(`${this.baseURL}/ping`);

            if (response.status === 200) {
                if (response.data) {
                    return true;
                }
            }

            return false;

        } catch (error) {
            console.log("Encompass Service Test Connection: ", error);
            return false; 
        }
    }

    public static getRequestEndpoint() { return localStorage.getItem("requestUri") ?? ENCOMPASS_REQUEST_API_URI; }
    public static getPluginEndpoint() { return localStorage.getItem("pluginUri") ?? ENCOMPASS_PLUGIN_API_URI; }
    public static getTokenEndpoint() { return localStorage.getItem("tokenUri") ?? ENCOMPASS_TOKEN_ENDPOINT_API_URI; }
    // TODO: These should come from state 
    public static getEncompassInstanceId() { return localStorage.getItem("encompassInstanceId") ?? ENCOMPASS_DEFAULT_INSTANCE_ID; }
    public static getConfigId() { return localStorage.getItem("configurationId") ?? ENCOMPASS_DEFAULT_CONFIG_ID; }
}

export default EncompassService;