import React, { useState, useEffect } from "react";
import { Box, Card, Stack, Select, InputLabel, FormControl, MenuItem, FormControlLabel, FormGroup, TextField, Checkbox, SelectChangeEvent, CardContent, CardActions, Button } from "@mui/material";
import GenericDialog from "../../../components/shared/GenericDialog";
import { IDocumentBundle, IDocumentHandlingPackageConfig } from "../../../models/configuration/bundles/IBundles";
import DTDataGrid from "../../../components/shared/config/DTDataGrid";
import { packageConfigColumns, mergePackageConfigColumns } from "./configbundlingdata";
import { useDocumentMappings } from "../../../hooks/useDocumentMappings";

interface ConfigDocumentHandlingDialogProps {
    open: boolean;
    documentBundle: IDocumentBundle;
    closeFxtn: () => void;
}

const OneFolderPerDocument = "OneFolderPerDocument";
const OneFolderPerPackage = "OneFolderPerPackage";

const ConfigDocumentHandlingDialog: React.FC<
    ConfigDocumentHandlingDialogProps
> = ({ open, documentBundle, closeFxtn }) => {

    const [documentBundleState, setDocumentBundleState] = useState<IDocumentBundle>(documentBundle);
    const documentMappings = useDocumentMappings();  // Custom hook to get document maps/lists

    useEffect(() => {
        setDocumentBundleState(documentBundle);
    }, [documentBundle]);

    const handleSelectChange = (e: SelectChangeEvent) => {
        const { name, value } = e.target;
        handlePropChange(name, value);
    };

    const handlePropChange = (name: string, value: any) => {
        console.log("🚀 ~ handlePropChange ~ name:", name);
        console.log("🚀 ~ setDocumentBundleState ~ setDocumentBundleState:", documentBundleState)
        setDocumentBundleState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleItemSelectChange = (e: SelectChangeEvent, item: any) => {
        const { name, value } = e.target;
        handleItemPropChange(name, value, item);
    };

    const handleItemInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        item: any
    ) => {
        const { name, value } = e.target;
        handleItemPropChange(name, value, item);
    };

    const handleItemPropChange = (name: string, value: any, item: any) => {
        item[name] = value;
        setDocumentBundleState((prevState) => ({
            ...prevState,
        }));
    };

    const addRow = () => {
        if (documentBundleState?.BundlingOption === OneFolderPerDocument) {
            var bundleItems = [
                ...documentBundleState?.DocumentHandlingPackageConfigs,
            ];
            var blankItem: IDocumentHandlingPackageConfig = {
                DocumentIndex: "",
                DocumentName: "",
                EncompassFolder: "",
                Suffix: "",
                ReuseFolder: false,
                EncompassAttachment: "",
            };

            bundleItems.push(blankItem);

            setDocumentBundleState((prevState) => ({
                ...prevState,
                DocumentHandlingPackageConfigs: bundleItems,
            }));
        }
        if (documentBundleState?.BundlingOption === OneFolderPerPackage) {
            // * TODO: Figure out what is needed here
        }
    };
    const removeRow = () => { };

    if (documentBundleState == null) return <></>;

    if (documentMappings == null) return <></>;

    return (
        <GenericDialog
            open={open}
            title={"Document Handling Configuration"}
            onClose={closeFxtn}
            cancelButtonText="Close"
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    height: "100%",
                }}
            >
                <Stack direction="row" justifyContent="start" spacing={1}>
                    <Box sx={{ flex: 1 }} className={["flexLeft"].join(" ")}>
                        <TextField
                            sx={{ marginTop: "10px" }}
                            label="Package ID"
                            name="PackageID"
                            value={documentBundleState?.PackageId}
                            fullWidth
                            variant="outlined"
                        />
                    </Box>

                    <Box sx={{ flex: 1 }} className={["flexRight"].join(" ")}>
                        {documentBundleState?.BundlingOption === OneFolderPerDocument ? (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e) =>
                                                handlePropChange(
                                                    "DocumentHandlingDuplicate",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label="Allow Document Handling Duplication"
                                    value={documentBundleState?.DocumentHandlingDuplicate}
                                />
                            </FormGroup>
                        ) : (
                            <></>
                        )}
                    </Box>
                </Stack>

                <Stack direction="row" justifyContent="start" spacing={1}>
                    <Box sx={{ flex: 1 }} className={["flexLeft"].join(" ")}>
                        <FormControl sx={{ flex: 1 }}>
                            <InputLabel>{"Bundling Option"}</InputLabel>
                            <Select
                                sx={{ marginTop: "10px" }}
                                label="Bundling Option"
                                name="BundlingOption"
                                value={documentBundleState?.BundlingOption}
                                onChange={handleSelectChange}
                                fullWidth
                                variant="outlined"
                            >
                                <MenuItem value={OneFolderPerDocument}>
                                    One Folder Per Document
                                </MenuItem>
                                <MenuItem value={OneFolderPerPackage}>
                                    One Folder Per Package
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ flex: 1 }} className={["flexRight"].join(" ")}>
                        {documentBundleState?.BundlingOption === OneFolderPerPackage ? (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e) =>
                                                handlePropChange(
                                                    "MergerConfigDuplicate",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label="Allow Merger Duplication"
                                    value={documentBundleState?.MergerConfigDuplicate}
                                />
                            </FormGroup>
                        ) : (
                            <></>
                        )}
                    </Box>
                </Stack>

                <Card sx={{ mt: 1 }}>
                    <CardContent>
                        {documentBundleState?.BundlingOption === OneFolderPerDocument ? (
                            <Box sx={{ flex: 1 }} className={["flexRight"].join(" ")}>
                                <DTDataGrid
                                    columns={packageConfigColumns(
                                        handleItemSelectChange,
                                        handleItemInputChange,
                                        ["All", "AuditLog", ...documentMappings?.Configuration?.DocumentMaps.map(x => x.DocumentIndex) ?? []]
                                    )}
                                    data={
                                        documentBundleState?.DocumentHandlingPackageConfigs ?? []
                                    }
                                    tableName="packageConfigs"
                                    containerSx={{ maxHeight: "30vh", minHeight: "30vh" }}
                                />
                            </Box>
                        ) : (
                            <Box sx={{ flex: 1 }} className={["flexRight"].join(" ")}>


                                <DTDataGrid
                                    columns={mergePackageConfigColumns(
                                        handleItemSelectChange,
                                        handleItemInputChange,
                                        ["All", "AuditLog", ...documentMappings?.Configuration?.DocumentMaps.map(x => x.DocumentIndex) ?? []]
                                    )}
                                    data={documentBundleState?.MergerPackageConfigs ?? []}
                                    tableName="mergepackageConfigs"
                                    containerSx={{ maxHeight: "30vh", minHeight: "30vh" }}
                                />
                            </Box>
                        )}

                        <CardActions>
                            <Button variant="outlined" size="small" onClick={addRow}>
                                Add
                            </Button>
                            <Button variant="outlined" size="small" onClick={removeRow}>
                                Remove
                            </Button>
                        </CardActions>
                    </CardContent>
                </Card>
            </Box>
        </GenericDialog>
    );
};

export default ConfigDocumentHandlingDialog;