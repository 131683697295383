import { IEventBundleConfiguration } from "../../models/configuration/bundles/IBundles";
import { Action, ActionType } from "../actions";

interface ConfigBundlesState {
  loading: boolean;
  error: string | null;
  data: IEventBundleConfiguration | null;
}

const initialState = { loading: false, error: null, data: null };

const configEventBundlesReducer = (
  state: ConfigBundlesState = initialState,
  action: Action
): ConfigBundlesState => {
  switch (action.type) {
    case ActionType.GET_CONFIG_BUNDLE_EVENT_DATA:
      return { loading: true, error: null, data: null };
    case ActionType.GET_CONFIG_BUNDLE_EVENT_DATA_FAILURE:
      return { loading: false, error: action.payload, data: null };
    case ActionType.GET_CONFIG_BUNDLE_EVENT_DATA_SUCCESS:
      return { loading: false, error: null, data: action.payload };
    default:
      return state;
  }
};

export default configEventBundlesReducer;