import { Backdrop, CircularProgress, Typography, useTheme } from "@mui/material";

interface ConfigLoadProps {
    loading: boolean;  // Opens it
    message?: string;  // Message passed in
}

const ConfigLoad: React.FC<ConfigLoadProps> = ({ loading, message }) => {
    const theme = useTheme();
    return (
        <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            open={loading}
        >
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor={theme.palette.info.main} />
                        <stop offset="100%" stopColor={theme.palette.primary.main} />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
            {message && (
                <Typography
                    sx={{ mt: 2, color: theme.palette.common.white, margin: 3 }}
                    variant="h6"
                >
                    {message}
                </Typography>
            )}
        </Backdrop>
    );
};

export default ConfigLoad;