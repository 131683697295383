import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";

interface configTopBarProps {
    save: () => void;
    test?: () => void;
    error: string | null;
    reset?: () => void;
    importConfig?: () => void;
    exportConfig?: () => void;
}

const ConfigTopBar: React.FC<configTopBarProps> = ({ save, test, error, reset, importConfig, exportConfig }) => {

    return (
        <div>
            <Card sx={{ height: "65px", margin: 2 }}>
                <CardContent>
                    <Box sx={{ display: "flex", flexDirection: "row",  justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                            {reset && (<Button sx={{ marginLeft: ".5rem" }} onClick={reset} variant="contained" size="small" >Reset</Button>)}
                            {test && (<Button sx={{ marginLeft: ".5rem", backgroundColor: "orange" }} onClick={test} variant="contained" size="small" >Test</Button>)}
                            {error && (
                                <Typography sx={{ width: "100%", flex: 1, marginLeft: "1rem", padding: 1, border: '1px solid black', borderRadius: '2px' }} 
                                    variant="body1" color="error">
                                    {error}
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <Button sx={{ marginRight: ".5rem"}} onClick={importConfig} variant="contained" size="small" >Import</Button>
                            <Button onClick={exportConfig} variant="contained" size="small" >Export</Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
}

export default ConfigTopBar;