import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IOriginState } from "../models/IOriginState";
 
const initialState = {elliPat: '', id: '', transaction:{}} satisfies IOriginState as IOriginState;

export const originSlice = createSlice({
    name: 'origin',
    initialState,
    reducers: {
        setOriginVals(state, action: PayloadAction<{pat: string, id: string}>){
            state.elliPat = action.payload.pat;
            state.id = action.payload.id;
        }
     } 
})
 
export const {setOriginVals } = originSlice.actions;
export default originSlice.reducer