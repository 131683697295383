import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Typography, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

interface InputDialogProps {
    open: boolean;
    mode: 'add' | 'edit' | 'delete' | 'view' | '';
    value?: string;
    _key: string | null;
    dataView?: string[] | [];
    confirmationMessage?: string;
    onSave: (mode: string, key: any, value: string) => void;
    onCancel: () => void;
    onDelete: () => void;
}

const InputDialogAdvanced: React.FC<InputDialogProps> = ({ open, mode, _key, value, dataView, confirmationMessage, onSave, onCancel, onDelete }) => {
    const [valueSt, setInputValueSt] = useState<string | undefined>(value);
    const [inputKeySt, setInputKeySt] = useState<any>(_key);
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('info');

    useEffect(() => {
        if (mode === 'add') {
            clearValues();
        } else if (mode === 'edit' || mode === 'view') {
            setInputValueSt(value || '');
            setInputKeySt(value || '');
        }
        
    }, [mode, _key, value]);

    const handleSave = () => {

        switch (mode) {

            case 'add':
                if (!inputKeySt || !valueSt) {
                    openAlert('Both text entries are required.', 'warning');
                    return;
                }
                onSave(mode, inputKeySt, valueSt);
                clearValues();
                break; 

            case 'edit':
                if (!valueSt) {
                    openAlert('Please enter a value.', 'warning');
                    return;
                }
                onSave(mode, inputKeySt, valueSt);
                clearValues();
                break;
            default:
                break
        }
    };

    // Alert Handling
    const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }

    const handleCancel = () => {
        clearValues();
        onCancel();
    }

    const clearValues = () => { 
        setInputValueSt('');
        setInputKeySt('');
    }

    return (
        <Dialog open={open} onClose={onCancel} fullWidth >
            <DialogTitle>
                {mode === 'add' && 'Add Item'}
                {mode === 'edit' && 'Edit Item'}
                {mode === 'delete' && 'Delete Item'}
                {mode === 'view' && 'View Data'}
            </DialogTitle>
            <DialogContent>
                {mode === 'delete' ? (
                    <p>{confirmationMessage || 'Are you sure you want to delete this item?'}</p>
                ) : mode === 'view' ? (
                    dataView && dataView.length > 0 ? (
                        dataView?.map((val, index) => (
                            <TextField key={index} value={val} multiline rows={3} fullWidth margin="dense"
                                InputProps={{ readOnly: true }} variant="filled"
                            />
                        ))
                    ) : (
                        <p>No data to view</p>
                    )
                ) : mode === 'add' || mode === 'edit' ? (
                    <div>
                        <Typography variant="subtitle1">Response Message</Typography>
                        {mode === 'edit' ? (
                            <TextField value={inputKeySt} multiline rows={3} fullWidth margin="dense"
                                InputProps={{ readOnly: true }} variant="filled"
                            />
                        ) : (
                            <TextField value={inputKeySt} onChange={(e) => setInputKeySt(e.target.value)} fullWidth required />
                        )}
                        <Typography variant="subtitle1">Response Override</Typography>
                        <TextField value={valueSt} onChange={(e) => setInputValueSt(e.target.value)} fullWidth required />
                    </div>
                ) : (
                    <TextField value={valueSt}
                        onChange={(e) => setInputValueSt(e.target.value)}
                        fullWidth required
                    />
                )}
            </DialogContent>
            <DialogActions>
                {mode !== 'view' && (
                    <>
                        <Button onClick={() => handleCancel()} color="secondary">Cancel</Button>
                        {mode === 'delete' ? (
                            <Button onClick={onDelete} color="error">Delete</Button>
                        ) : (
                            <Button onClick={handleSave} color="primary">Save</Button>
                        )}
                    </>
                )}
                {mode === 'view' && (
                    <Button onClick={onCancel} color="primary">Close</Button>
                )}
            </DialogActions>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alertOpen}
                autoHideDuration={4000}
                onClose={handleSnackBarClose}
            >
                <MuiAlert
                    onClose={handleSnackBarClose}
                    severity={alertSeverity}
                    sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                    {alertMessage}
                </MuiAlert>
            </Snackbar>
        </Dialog>

    );
};

export default InputDialogAdvanced;