import { IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";

import { Action, ActionType } from "../actions";

interface DefaultValueState {
    loading: boolean;
    error: string | null;
    data: IPluginConfiguration | null;
}

const initialState = { loading: false, error: null, data: null }

const configDefaultValueReducer = (
    state: DefaultValueState = initialState,
    action: Action
): DefaultValueState => {
    switch (action.type) {
        // GET DATA
        case ActionType.GET_CONFIG_MISC:
            return { loading: true, error: null, data: null };
        case ActionType.GET_CONFIG_MISC_FAILURE:
            return { loading: false, error: action.payload, data: null };
        case ActionType.GET_CONFIG_MISC_SUCCESS:
            return { loading: false, error: null, data: action.payload };
        
        // SAVE DATA
        case ActionType.SAVE_CONFIG_MISC:
            return { ...state, loading: true, error: null, data: state.data };
        case ActionType.SAVE_CONFIG_MISC_FAILURE:
            return { loading: false, error: action.payload, data: state.data};
        case ActionType.SAVE_CONFIG_MISC_SUCCESS:
            return { loading: false, error: null, data: action.payload };
        default:
            return state;
    }
};

export default configDefaultValueReducer;