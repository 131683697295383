import { IErrorMessageOverrides } from "../models/configuration/plugin/IConfiguration";

const base64Encode = (str: string) => { return btoa(str);}
const base64Decode = (str: string) => { return atob(str);}

const base64DecodeMessages = (messages: IErrorMessageOverrides[]): IErrorMessageOverrides[] => {
    // Iterate through the messages and decode into a new IErrorMessageOverrides array

    return messages.map((message) => {
        return {
            ...message,
            ResponseMessage: base64Decode(message.ResponseMessage),
            ResponseOverride: base64Decode(message.ResponseOverride)
        }
    });
}


export { base64Encode, base64Decode, base64DecodeMessages };