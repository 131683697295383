import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';

interface GenericDialogProps {
  open: boolean;
  title: string;
  cancelButtonText?: string;
  submitButtonText?: string;
  onClose: () => void;
  children: React.ReactNode;
}

const GenericDialog: React.FC<GenericDialogProps> = ({
  open,
  title,
  cancelButtonText = "Cancel",
  onClose,
  children,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
          {children}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenericDialog;