export interface IDataColumn {
    name: string | null;
    fieldId: string;
    datatype: DTDataGridColumnType;
    callbackFunction?: (e : any, item : any) => void;
    foreColor?: string;
    linkText?: string
    alignment?: DTDataGridAlignment,
    dataFunction?: (item : any) => void;
    dataChangeFunction?: (e: any, item : any) => void;
    dataOptions?: Array<any>;
    readonly?: boolean,
    editOnClick?: boolean
  }

  export enum DTDataGridColumnType {
    text,
    checkbox,
    dropdownlist,
    link
  }

  export enum  DTDataGridAlignment {
    left,
    right,
    center
  }
