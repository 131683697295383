import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import IIntegration from "../../models/configuration/servers/IIntegration";
import ISaveIntegration from "../../models/configuration/servers/ISaveIntegration";

const saveConfigServerData = (configId: string, data: IIntegration, token: string | null) => {
    return async (dispatch: Dispatch<Action>) => {

        dispatch({
            type: ActionType.SAVE_CONFIG_SERVERS
        });

        const pluginService = new PluginService();
        
        try {
            const requestBody = {
                "ConfigurationId": configId,
                "Configuration": data
            }
            
            await pluginService.saveConfig<ISaveIntegration>(token, "integration", configId, requestBody);
            dispatch({
                type: ActionType.SAVE_CONFIG_SERVERS_SUCCESS,
                payload: data
            });

        } catch(error: any) {
            console.log("saveConfigServerData: FAILURE");
            if (error instanceof Error) {
                const payloadErrorMessage = `Failed to Save Servers Configuration Data: (${error.message})`;
                dispatch({
                    type: ActionType.SAVE_CONFIG_SERVERS_FAILURE,
                    payload: payloadErrorMessage
                });
                throw new Error(payloadErrorMessage);
            }
        }
    }
}

export default saveConfigServerData;