import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";

const getConfigMessagesData = (configId: string, token: string | null): (dispatch: Dispatch<Action>, getState: () => any) => Promise<void> => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {

        dispatch({ type: ActionType.GET_MESSAGES });

        const plugInData = getState().configPlugInData.data;
        if (plugInData !== null) { // Checking for exiting state data  
            dispatch({
                type: ActionType.GET_MESSAGES_SUCCESS,
                payload: plugInData
            });
            return;
        }

        try {

            const payload = await new PluginService().getConfig<IPluginConfiguration>(token, "plugin", configId);
            dispatch({
                type: ActionType.GET_MESSAGES_SUCCESS,
                payload: payload
            });

        } catch (error: any) {
            if (error instanceof Error) {
                const payloadErrorMessage = `Failed to fetch Messages Data: (${error.message})`;
                dispatch({
                    type: ActionType.GET_MESSAGES_FAILURE,
                    payload: payloadErrorMessage
                });
            }
        }
    }
}

export default getConfigMessagesData;