import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";

// This action creator gets the Default values from an api call or state
const getConfigMiscData = (configId: string, token: string | null): (dispatch: Dispatch<Action>, getState: () => any) => Promise<void> => {
    
    return async (dispatch: Dispatch<Action>, getState: () => any) => {

        dispatch({ type: ActionType.GET_CONFIG_MISC});

        try {
            const { data: pluginConfiguration } = getState().configPlugInData;

            if (pluginConfiguration !== null) {
                dispatch({
                    type: ActionType.GET_CONFIG_MISC_SUCCESS,
                    payload: pluginConfiguration
                })
            } else {
                const payload = await new PluginService().getConfig<IPluginConfiguration>(token, "plugin", configId);
                dispatch({
                    type: ActionType.GET_CONFIG_MISC_SUCCESS,
                    payload: payload
                })
            }
            
        } catch (error: any) {
            console.error("Get Default Values Data");
            if (error instanceof Error) {
                const payloadErrorMessage = `Failed to fetch Default Values Data: (${error.message})`;
                dispatch({
                    type: ActionType.GET_CONFIG_MISC_FAILURE,
                    payload: payloadErrorMessage,
                });
            }
        }

    }
}

export default getConfigMiscData;