import React from 'react';
import { Card, CardContent, List, ListItemButton, ListItemText } from '@mui/material';
import { IOtherControl } from '../../../models/configuration/plugin/IConfiguration';

interface ControlsListProps {
    otherControls: IOtherControl[] | null,
    selectedControlIdx: number | null,
    selectControl: (index: number, id: string) => void,
}

const ControlsList: React.FC<ControlsListProps> =
    ({ otherControls, selectedControlIdx, selectControl }) => {

        return (

            <Card
                sx={{
                    display: 'flex', flexDirection: 'column',
                    margin: 2
                }}
            >
                <CardContent>
                    <List>
                        {otherControls?.map((control, index) => (
                            <ListItemButton
                                key={index}
                                sx={{ color: 'blue' }}
                                selected={selectedControlIdx === index}
                                onClick={() => selectControl(index, control.Id as string)}
                            >
                                <ListItemText primary={control.FriendlyName} />
                            </ListItemButton>
                        ))}
                    </List>
                </CardContent>
            </Card>
        );
    }

export default ControlsList;