import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appSlice from "./appSlice";
import originSlice from "./originSlice";
import { 
    configServersReducer, 
    configPlugInReducer, 
    configOtherControlsReducer, 
    configPackagesReducer,
    configDeliveryTypesReducer,
    configImpersonationReducer,
    configSendValidationsReducer,
    configMessagesReducer,
    configDocumentBundlesReducer, 
    configEventBundlesReducer,
    configDocumentMappingReducer,
    configMiscReducer

} from "./configReducers";

const reducer = combineReducers({
    appSlice,
    originSlice,
    configServers: configServersReducer,
    configPlugInData: configPlugInReducer,
    configOtherControls: configOtherControlsReducer,
    configPackages: configPackagesReducer,
    configDeliveryTypes: configDeliveryTypesReducer,
    configImpersonation: configImpersonationReducer,
    configSendValidations: configSendValidationsReducer,
    configMessages: configMessagesReducer,
    configDocumentBundles: configDocumentBundlesReducer,
    configEventBundles: configEventBundlesReducer,
    configDocumentMapping: configDocumentMappingReducer,
    configMiscDefaults: configMiscReducer
})

export const store = configureStore({
    reducer
})

export type AppStore = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = AppStore['dispatch'];