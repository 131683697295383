import { Grid, Typography } from "@mui/material"

const LandingPage: React.FC = () => {  
    return (
        <>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center">
                <Grid item>
                    <Typography variant="h5">
                        Please wait. Environment is loading.
                    </Typography>
                </Grid>
            </Grid>
        </>
    ) 
}

export default LandingPage