import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { Box, SelectChangeEvent, Snackbar, FormControlLabel, Checkbox } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from "../../hooks/useActions";
import "../../assets/style.css";
import ConfigLoad from "../../components/shared/config/ConfigLoad";
import EncompassService from "../../services/encompassService";
import { IDeliveryType } from "../../models/configuration/plugin/IConfiguration";
import { RootState } from "../../state/store";
import { useSelector } from "react-redux";
import DeliveryTypesList from "../../components/configuration/deliveryTypes/DeliveryTypesList";
import DeliveryTypeDetails from "../../components/configuration/deliveryTypes/DeliveryTypeDetails";
import DeliveryTypesTab from "../../components/configuration/deliveryTypes/DeliveryTypesTab";
import ConfigBottomBar from "../../components/shared/config/ConfigBottomBar";
import ConfigTopBar from "../../components/shared/config/ConfigTopBar";

const ConfigDeliveryMethods: React.FC = () => {

    // Fetch Data
    const { getConfigDeliveryTypesData, saveConfigDeliveryTypesData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const { data, error, loading } = useTypedSelector((state) => state.configDeliveryTypes);

    // Delivery Type State 
    const [deliveryTypes, setDeliveryTypes] = useState<IDeliveryType[]>([]);
    const [allowMultiDeliveries, setAllowMultiDeliveries] = useState<boolean>(false);
    const [selectedDeliveryType, setSelectedDeliveryType] = useState<IDeliveryType | null>(null);
    const [selectedDeliveryTypeIdx, setSelectedDeliveryTypeIdx] = useState<string | null>(null);
    const [selectedConfigId, setSelectedConfigId] = useState<string>('');

    // Sub States
    const [tabIndex] = useState<number>(1);
    const [errorMessage, setErrorMessage] = useState<string>('');

     // State Confirmations
     const [alertOpen, setAlertOpen] = useState(false);
     const [saveError, setSaveError] = useState<boolean>(false);
     const [alertMessage, setAlertMessage] = useState('');
     const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('info');
 
    // Refs
    const hasFetchedData = useRef(false);
    const initialTypeSelected = useRef(false);

    const fetchPageData = useCallback((configId: string) => {
        getConfigDeliveryTypesData(configId, accessToken);
    }, [getConfigDeliveryTypesData, accessToken]);

    // Mount control
    useEffect(() => {
        if (!hasFetchedData.current && !data) {
            const configId = EncompassService.getConfigId() as string;
            setSelectedConfigId(configId);
            const delayFetch = setTimeout(async () => {
                fetchPageData(configId);
                hasFetchedData.current = true;
            }, 500)
            return () => clearTimeout(delayFetch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Data found
    useEffect(() => {
        if (!saveError && data !== null) {
            setDeliveryTypes(data.Configuration?.DeliveryTypes);
            setAllowMultiDeliveries(data.Configuration?.AllowMultiDelivery);
            const configId = EncompassService.getConfigId() as string;
            setSelectedConfigId(configId);
        }
    }, [data, saveError]);

    useEffect(() => {
        if (deliveryTypes && deliveryTypes.length > 0 && !initialTypeSelected.current) {
            setInitialDeliveryType();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryTypes]);

    // Set Initial Delivery Type
    const setInitialDeliveryType = () => {
        if (deliveryTypes && deliveryTypes.length > 0) {
            const initialType = deliveryTypes.find(p => p.Enabled === true);
            if (initialType) {  
                setSelectedDeliveryTypeIdx(initialType.Id);    
                setSelectedDeliveryType(initialType);
                initialTypeSelected.current = true;
            }
        }
    }

    // New Delivery Method Selected 
    const selectDeliveryType = (dtId: string) => {
        setSelectedDeliveryTypeIdx(dtId);
        setSelectedDeliveryType(deliveryTypes.find(p => p.Id === dtId) || null);
    }

    // Delivery Type Updated 
    useEffect(() => {
        if (selectedDeliveryType != null && deliveryTypes != null && deliveryTypes.length > 0) {
            const existingDeliveryMethod = deliveryTypes.find((p) => p.Id === selectedDeliveryType.Id);
            if (existingDeliveryMethod && existingDeliveryMethod !== selectedDeliveryType) {
                const updatedDeliveryMethods = deliveryTypes.map((p) => p.Id === selectedDeliveryType.Id ? selectedDeliveryType : p);
                refreshLists(updatedDeliveryMethods);
            }
        }
    }, [selectedDeliveryType, deliveryTypes]);

    // Delivery Type Property Changes   
    const handleEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => { handleControlPropChange("Enabled", event.target.checked); };
    const handleOrderChange = (event: SelectChangeEvent) => { handleControlPropChange("Order", event.target.value); };
    const handleCaptionChange = (event: React.ChangeEvent<HTMLInputElement>) => { handleControlPropChange("Caption", event.target.value); };
    const handleToolTipChange = (event: React.ChangeEvent<HTMLInputElement>) => { handleControlPropChange("ToolTipText", event.target.value); };
    const handleAutoSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => { handleControlPropChange("AutoSelectWhen", event.target.value); };      
    const handleControlPropChange = (property: keyof IDeliveryType, value: any) => {
        if (selectedDeliveryType) {
            const updatedDeliveryMethod = { ...selectedDeliveryType, [property]: value };
            setSelectedDeliveryType(updatedDeliveryMethod);
        }
    }

    const updatedSelectedDeliveryType = (updatedControl: IDeliveryType) => { setSelectedDeliveryType(updatedControl); }

    // Update Delivery Type List
    const refreshLists = (updateDeliveryTypes: IDeliveryType[]) => {
        setDeliveryTypes(updateDeliveryTypes);
    }

    const save = async () => {
        try {
            await saveConfigDeliveryTypesData(selectedConfigId, deliveryTypes, allowMultiDeliveries, accessToken);
            openAlert("Successfully Saved Changes", "success");
            setSaveError(false);
            setErrorMessage('');
        } catch (e) {
            const error = `${e}`;
            console.log("Save Error: ", error);
            setSaveError(true);
            setErrorMessage(error);
        }
    }

    function handleAllowMultiDeliveriesChange(event: ChangeEvent<HTMLInputElement>, checked: boolean): void {
        setAllowMultiDeliveries(!allowMultiDeliveries);
    }

     // Alert Handling 
     const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
     const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
         setAlertSeverity(severity);
         setAlertMessage(message);
         setAlertOpen(true);
     }

    return (
        <>
            <section>
                <div>
                    {loading && <ConfigLoad loading={loading} message="Loading Configuration" />}
                </div>
                <div>

                    <Box
                        mt={1} component="main" sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1, overflow: "auto", minHeight: '100vh', margin: "2px"
                        }}
                    >

                        <ConfigTopBar save={save} error={error || errorMessage || null} />
                        <div className="configDeliveryTypesContainer">
                            {/* Left Side */}
                            <div className="configDeliveryTypeSubContainer-Left">
                                <FormControlLabel
                                    sx={{ paddingLeft: "2rem" }}
                                    control={<Checkbox onChange={handleAllowMultiDeliveriesChange} checked={allowMultiDeliveries ?? false} />}
                                    label="Allow Multi-Deliveries"
                                />

                                <DeliveryTypesList
                                    deliveryTypes={deliveryTypes}
                                    selectedDeliveryTypeIdx={selectedDeliveryTypeIdx}
                                    selectDeliveryType={selectDeliveryType}
                                />
                            </div> 
                            <div>
                                {/* Right Side */}
                                <div className="configDeliveryTypeSubContainer-Right"
                                    style={{ pointerEvents: selectedDeliveryType ? 'auto' : 'none', opacity: selectedDeliveryType ? 1 : 0.5 }}>
                                    <Box sx={{ margin: 5 }}>
                                        <DeliveryTypeDetails
                                            selectedDeliveryType={selectedDeliveryType}
                                            handleOnEnabledChange={handleEnabledChange}
                                            handleOnOrderChange={handleOrderChange}
                                            handleOnCaptionChange={handleCaptionChange}
                                            handleOnToolTipTextChange={handleToolTipChange}
                                            handleOnAutoSelectChange={handleAutoSelectChange}
                                        />
                                    </Box>
                                    <Box>
                                        <DeliveryTypesTab
                                            tabIndex={tabIndex}
                                            selectedDeliveryType={selectedDeliveryType}
                                            updateParent={updatedSelectedDeliveryType}
                                        />
                                    </Box>
                                </div>
                            </div>
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={alertOpen}
                            autoHideDuration={4000}
                            onClose={handleSnackBarClose}
                        >
                            <MuiAlert
                                onClose={handleSnackBarClose}
                                severity={alertSeverity}
                                sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                                {alertMessage}
                            </MuiAlert>
                        </Snackbar>
                    </Box>
                    <ConfigBottomBar save={save} />
                </div>
            </section>
        </>
    )
}

export default ConfigDeliveryMethods;