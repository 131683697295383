import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import DTDataGrid from "../../../components/shared/config/DTDataGrid";
import { otherDocColumns } from "./configbundlingdata";
import { IEventBundle } from "../../../models/configuration/bundles/IBundles";
import { Typography } from "@mui/material";
import ConfigLoad from "../../../components/shared/config/ConfigLoad";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import EncompassService from "../../../services/encompassService";
import "../../../assets/style.css";

const OtherDocumentBundling: React.FC = () => {
  // Reducers
  const { getConfigBundleEventData } = useActions();
  const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
  const { data, error, loading } = useTypedSelector((state) => state.configEventBundles);

  // State
  const [otherDocBundles, setOtherDocBundles] = useState<Array<IEventBundle>>([]);
  // * This will be used 
  const [selectedConfigId, setSelectedConfigId] = useState<string>('');

  // Refs
  const hasFetched = useRef(false);

  const fetchBundles = useCallback((configId: string) => {
    getConfigBundleEventData(configId, accessToken);
  }, [getConfigBundleEventData, accessToken]);

  useEffect(() => {
    // Initial call once the component mounts to fetch the state
    if (!hasFetched.current) {
      const configId = EncompassService.getConfigId() as string;
      setSelectedConfigId(configId);
      fetchBundles(configId);
      hasFetched.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data != null) {
      setOtherDocBundles(data.Configuration.Bundling);
    }
  }, [data]); // Run once the component mounts

  function editItem(e: any, item: any) {
    alert("Edit " + item.PackageId);
  }

  function deleteItem(e: any, item: any) {
    alert("Delete" + item.PackageId);
  }

  return (
    <>
      <div>
        {loading && (
          <ConfigLoad loading={loading} message="Loading Configuration" />
        )}
      </div>
      {error ? (
        <Typography
          sx={{
            padding: 1,
            border: "1px solid black",
            borderRadius: "2px",
          }}
          variant="body1"
          color="error"
        >
          Unable to load data: {error}
        </Typography>
      ) : (
        <DTDataGrid
          data={otherDocBundles}
          columns={otherDocColumns(editItem, deleteItem)}
          tableName="otherdocs"
        />
      )}
    </>
  );
};

export default OtherDocumentBundling;