import IIntegration from "../../models/configuration/servers/IIntegration";
import { Action, ActionType } from "../actions";

interface ConfigServersState {
    loading: boolean;
    error: string | null;
    data: IIntegration | null
}

const initialState = { loading: false, error: null, data: null }

const configServersReducer = (
    state: ConfigServersState = initialState,
    action: Action
): ConfigServersState => {

    switch (action.type) {
        case ActionType.GET_CONFIG_SERVERS:  // This is the process of getting the data
            return { loading: true, error: null, data: null };
        case ActionType.GET_CONFIG_SERVERS_FAILURE:
            return { loading: false, error: action.payload, data: null };
        case ActionType.GET_CONFIG_SERVERS_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.SAVE_CONFIG_SERVERS:
            return { loading: true, error: null, data: null };
        case ActionType.SAVE_CONFIG_SERVERS_FAILURE:
            return { loading: false, error: action.payload, data: null }
        case ActionType.SAVE_CONFIG_SERVERS_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        default:
            return state;
    }
};

export default configServersReducer;